<template>
    <div class="modal-select-signup-method">
        <p class="m-b-28 f-16 f-medium c-black">{{ title }}</p>

        <!--        <div v-if="step === 0" class="btns">-->
        <!--            <button class="btn signup-facebook m-b-12" @click="onClickFacebook">-->
        <!--                <img class="img" src="@/assets/images/facebook_white.svg" />-->
        <!--                <p class="label" v-html="$translate('SIGNUP_FACEBOOK')" />-->
        <!--            </button>-->
        <!--            <button class="btn btn-primary" @click="onClickEmail">-->
        <!--                <i class="material-icons">email</i>-->
        <!--                <p class="label" v-html="$translate('SIGNUP_EMAIL')" />-->
        <!--            </button>-->
        <!--        </div>-->

        <div class="btns email-step">
            <button class="btn m-b-12" @click="signupWithEmail">
                <p class="label">이메일로 가입</p>
            </button>
            <button class="btn" @click="signinWithEmail">
                <p class="label" v-html="$translate('SIGNIN_EMAIL')" />
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalSelectSignupMethod',
    props: ['options'],
    data: () => ({
        step: 0,
    }),
    computed: {
        title() {
            if (this.step === 1) return '이메일로 시작하기'

            return this.$translate('START_EMAIL')
        },
    },
    methods: {
        onClickEmail() {
            this.step += 1
        },
        signupWithEmail() {
            this.$emit('close', 'signupWithEmail')
        },
        signinWithEmail() {
            this.$emit('close', 'signinWithEmail')
        },
        onClickFacebook() {
            this.$emit('close', 'signupWithFacebook')
        },
    },
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#372E66',
                bottom: '#FFFFFF',
            },
        })
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#8A74FF',
                bottom: '#FFFFFF',
            },
        })
    },
}
</script>

<style lang="scss" scoped>
.modal-select-signup-method {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    border-radius: 12px 12px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px;

    .btns {
        width: 100%;
    }

    .btn {
        position: relative;
        width: 100%;
        height: 48px;
        color: white;

        .img,
        .material-icons,
        .label {
            position: absolute;
        }

        .img,
        .material-icons {
            left: 16px;
        }

        .label {
            left: 50%;
            transform: translateX(-50%);
        }

        &.signup-facebook {
            background-color: $blue-facebook;
        }
    }

    .email-step {
        .btn {
            color: $grey-09;
            background-color: $grey-02;
        }
    }
}
</style>
